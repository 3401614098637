<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <common-menu-bar />
      <div class="group_2 flex-row justify-between">
        <img
          class="thumbnail_2"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/home/SketchPng21a141b7dd1acd150fad6c0d163f64ba2fba6c43fdf89d2060753b9803942add.png"
        />
        <span class="text_8">案元网·一个精做知识产权侵权打击的服务平台</span>
        <img
          class="thumbnail_3"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/home/SketchPng21a141b7dd1acd150fad6c0d163f64ba2fba6c43fdf89d2060753b9803942add.png"
        />
      </div>
      <div class="text-wrapper_1 flex-row">
        <span class="text_9">让维权更简单</span>
        <span class="text_10">让维权更简单</span>
      </div>
      <div class="group_3 flex-row">
        <div class="box_4 flex-row justify-between">
          <div class="section_1 flex-row">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/home/SketchPngf7f5d86e8bcb6a1d4ef175679b6af5e13b68795819e7c5f3615c696dce075341.png"
            />
            <div class="box_5 flex-col justify-between">
              <div class="text-group_1 flex-col justify-between">
                <span class="text_11">我是权利人</span>
                <span class="text_12">Obligee</span>
              </div>
              <div class="box_6 flex-row" @click="gotoLogin('Obligee')" style="cursor: pointer;">
                <div class="image-text_1 flex-row justify-between align-center">
                  <span class="text-group_2">我要维权</span>
                  <img
                    class="thumbnail_4"
                    referrerpolicy="no-referrer"
                    src="http://qiniu.anyuanip.com/home/home/SketchPng73d12b71c844eede8d3c5a190bc216c84ccdbe895e0c589f8369cf314d0c9351.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section_2 flex-col">
            <div class="group_4 flex-row justify-between">
              <div class="group_5 flex-col"></div>
              <div class="text-group_3 flex-col justify-between">
                <span class="text_13">我是律师</span>
                <span class="text_14">LAWYER</span>
              </div>
            </div>
            <div class="group_6 flex-row">
              <div class="group_7 flex-row" @click="gotoLogin('LawyerTeam')" style="cursor: pointer;">
                <div class="image-text_2 flex-row justify-between align-center">
                  <span class="text-group_2">我要案源</span>
                  <img
                    class="thumbnail_5"
                    referrerpolicy="no-referrer"
                    src="http://qiniu.anyuanip.com/home/home/SketchPng73d12b71c844eede8d3c5a190bc216c84ccdbe895e0c589f8369cf314d0c9351.png"
                  />
                </div>
              </div>
            </div>
            <div class="image-wrapper_1 flex-col">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/home/SketchPngedb05a638b3c8dcd6aaaf5df24d8f7bd5d21a299f7f70b9ad63c2f8513458d5d.png"
              />
            </div>
          </div>
          <div class="section_3 flex-row">
            <img
              class="image_3"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/home/SketchPng439099a2818399bbb32fa80d33e74d6d9e588f8c70c6c161e03c33e1c9648d83.png"
            />
            <div class="section_4 flex-col justify-between">
              <div class="text-group_5 flex-col justify-between">
                <span class="text_15">我是调查员</span>
                <span class="text_16">investigator</span>
              </div>
              <div class="section_5 flex-row" @click="gotoLogin('Investigator')" style="cursor: pointer;">
                <div class="image-text_3 flex-row justify-between align-center">
                  <span class="text-group_2">我要调查</span>
                  <img
                    class="thumbnail_6"
                    referrerpolicy="no-referrer"
                    src="http://qiniu.anyuanip.com/home/home/SketchPng7a2b14ca3e1abf97658546762d2cc69dffe1f90ca97183244ad8730ff5e4ea8c.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="image-wrapper_2 flex-row">
        <img
          class="label_2"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/home/SketchPng226bf29c93d111c322cd71f190393c58d2d5c8b372f2f09ecf207d661e7ce8ee.png"
        />
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="group_8 flex-col justify-end">
        <div class="text-group_7 flex-col justify-between">
          <span class="text_17">维权内容</span>
          <span class="text_18">
            案元科技专注于知识产权维权领域8年，熟悉电商平台维权治理，线上、线下的侵权线索发现及打击
          </span>
        </div>
        <div class="list_1 flex-row">
          <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
            <div v-if="item.slot1 === 1" class="block_3 flex-col"><div class="group_9 flex-col"></div></div>
            <div v-if="item.slot3 === 3" class="block_4 flex-col"><div class="block_5 flex-col"></div></div>
            <img
              v-if="item.slot2 === 2"
              class="image_4"
              referrerpolicy="no-referrer"
              :src="item.specialSlot2.lanhuimage0"
            />
            <span class="text_19" v-html="item.lanhutext0"></span>
          </div>
        </div>
        <img
          class="image_5"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/home/SketchPng6227749b4d0647ccb8627a707e6802cb4ef8ad2a9eed5fec3bc8a2a2f275cb0c.png"
        />
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/home/SketchPng0ff37c28ee5f3c6298f199d192f7c045fe1e051ac5ca649ded01857a4b43f7a0.png"
        />
      </div>
      <div class="group_10 flex-col">
        <span class="text_20">0成本维权·让维权启动更简单</span>
        <div class="group_11 flex-row">
          <span class="paragraph_1">
            Zero&nbsp;
            <br />
            &nbsp;&nbsp;cost
          </span>
          <div class="box_7 flex-col">
            <div class="text-wrapper_2 flex-row justify-between">
              <span class="text_21">成本零</span>
              <span class="text_22">Zero&nbsp;cost</span>
            </div>
            <div class="section_6 flex-row justify-between">
              <span class="text_23">权利人无需支付相关维权费用</span>
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/home/SketchPngfb924602ed1c53bba0fd07573626f1ccf0aaf9e33f6a14f870e24814c02f5018.png"
              />
            </div>
            <div class="section_7 flex-row">
              <div class="text-group_8 flex-col justify-between">
                <span class="text_24">效果好</span>
                <span class="text_25">惩罚侵权商，净化并震慑市场</span>
              </div>
              <div class="group_12 flex-col justify-between">
                <span class="text_26">Good&nbsp;effect</span>
                <img
                  class="image_8"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/home/SketchPng28cdc534a2eb22e885b190bec660dfef98da6a2b27eb4cb5998aff5c12d7c2de.png"
                />
              </div>
            </div>
          </div>
          <span class="paragraph_2">
            Good&nbsp;
            <br />
            ffect
          </span>
        </div>
      </div>
      <div class="group_13 flex-col">
        <div class="text-group_9 flex-col justify-between">
          <span class="text_27">案件管理系统·让案件管理更简单</span>
          <span class="text_28">案元网案件管理系统可协助各维权节点人员，清晰地掌握案件进度</span>
        </div>
        <div class="procedure-steps">

        </div>
      </div>
    </div>
    <div class="block_8 flex-col">
      <div class="group_20 flex-row">
        <div class="text-group_18 flex-col justify-between">
          <span class="text_39">优秀合作伙伴·让案件办理更简单</span>
          <span class="text_40">案元网将办案链条中优秀的团队引入到平台系统中，各环节人员协同完成工作</span>
        </div>
      </div>
      <div class="group_21 flex-row">
        <div class="text-wrapper_3 flex-col"><span class="text_41">优质案源</span></div>
      </div>
      <div class="group_22 flex-row justify-between">
        <div class="text-wrapper_4 flex-col"><span class="text_42">挖掘侵权线索</span></div>
        <div class="section_9 flex-col">
          <div class="image-wrapper_6 flex-row justify-between">
            <img
              class="thumbnail_10"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/home/SketchPngf6443b9083a68b01cd5f4607b228184afd74dd772d3857c283ced6e110b126cc.png"
            />
            <img
              class="thumbnail_11"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/home/SketchPngf6443b9083a68b01cd5f4607b228184afd74dd772d3857c283ced6e110b126cc.png"
            />
          </div>
          <div class="box_13 flex-row justify-between">
            <img
              class="thumbnail_12"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/home/SketchPngf6443b9083a68b01cd5f4607b228184afd74dd772d3857c283ced6e110b126cc.png"
            />
            <div class="block_9 flex-col justify-center">
              <div class="group_23 flex-col">
                <div class="box_14 flex-col justify-center"><div class="block_10 flex-col"></div></div>
              </div>
            </div>
            <img
              class="thumbnail_13"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/home/SketchPngf6443b9083a68b01cd5f4607b228184afd74dd772d3857c283ced6e110b126cc.png"
            />
          </div>
          <div class="box_15 flex-row justify-between">
            <div class="group_24 flex-row role-circle">
              <div class="image-text_12 flex-col justify-between">
                <img
                  class="label_4"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/home/SketchPng6292eaa3da7467a32d3193cab78bb1e3cd8d5e75eedc906eba860877f4552294.png"
                />
                <span class="text-group_19">公证处</span>
              </div>
            </div>
            <div class="group_25 flex-row role-circle">
              <div class="image-text_13 flex-col justify-between">
                <img
                  class="image_17"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/home/SketchPng8f9f7960165dd819528ee5b77c1bcd5eae73f22e55b42cbed3178abfa3d4aa58.png"
                />
                <span class="text-group_20">调解机构</span>
              </div>
            </div>
          </div>
          <img
            class="thumbnail_14"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/home/SketchPngf6443b9083a68b01cd5f4607b228184afd74dd772d3857c283ced6e110b126cc.png"
          />
        </div>
        <div class="text-wrapper_5 flex-col"><span class="text_43">&nbsp;办理案件</span></div>
      </div>
      <div class="group_26 flex-row justify-between">
        <div class="text-wrapper_6 flex-col"><span class="text_44">证据固定</span></div>
        <div class="text-wrapper_7 flex-col"><span class="text_45">协助消化案件</span></div>
      </div>
      <div class="group_27 flex-row role-circle">
        <div class="image-text_14 flex-col justify-between">
          <img
            class="image_18"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/home/SketchPng6c663056e14e312d8bf25c54405d2dacc6274f72ee8a355aa284dba4be6e8ad1.png"
          />
          <span class="text-group_21">权利人</span>
        </div>
      </div>
      <div class="group_28 flex-row role-circle">
        <div class="image-text_15 flex-col justify-between">
          <img
            class="image_19"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/home/SketchPng91cf45d85e947875d93ca07a2ab99c99fa6d4cc2a27930ea42d23f01f1b5d7c7.png"
          />
          <span class="text-group_22">调查员</span>
        </div>
      </div>
      <div class="group_29 flex-row role-circle">
        <div class="image-text_16 flex-col justify-between">
          <img
            class="label_5"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/home/SketchPng554c4961e78b54b6208dfa2465766d831ea69022fa28a7534dccac43750352fe.png"
          />
          <span class="text-group_23">律师</span>
        </div>
      </div>
    </div>
    <div class="block_11 flex-col">
      <div class="text-group_24 flex-col justify-between">
        <span class="text_46">专业调查团队·让线索发现更简单</span>
        <span class="text_47">案元网利用网络数据爬取、手机端线索录入等系统，减少重复劳动，提升线索挖掘效率</span>
      </div>
      <div class="group_30 flex-row justify-between">
        <div class="group_31 flex-row">
          <div class="text-group_25 flex-col justify-between">
            <span class="text_48">速度快</span>
            <span class="text_49">调查快，取证快，出公证书快</span>
          </div>
        </div>
        <div class="group_32 flex-row">
          <div class="text-group_26 flex-col justify-between">
            <span class="text_50">主体准</span>
            <span class="text_51">确保侵权主体准确，不准确不出证</span>
          </div>
        </div>
        <div class="group_33 flex-row">
          <div class="text-group_27 flex-col justify-between">
            <span class="text_52">覆盖全</span>
            <span class="text_53">线上、线下全覆盖调查打击</span>
          </div>
        </div>
      </div>
      <img
        class="image_20"
        referrerpolicy="no-referrer"
        src="http://qiniu.anyuanip.com/home/home/SketchPng785b3ec7955751e5982d1903cf43016c4bdc33d3773f99f42e655ce1fb03c6a7.png"
      />
      <img
        class="image_21"
        referrerpolicy="no-referrer"
        src="http://qiniu.anyuanip.com/home/home/SketchPng8c0fdbc11072f4d53708ffc55107871ef5115c405dd367a769fbb7705cd94dd6.png"
      />
      <img
        class="image_22"
        referrerpolicy="no-referrer"
        src="http://qiniu.anyuanip.com/home/home/SketchPng2ebc2d509a3713cf98a0b6d4af68b188919c5236ddbc1fcc97091a69b9f2e109.png"
      />
    </div>
    <div class="block_12 flex-col">
      <div class="text-wrapper_8 flex-row"><span class="text_54">案元科技服务内容</span></div>
      <div class="block_13 flex-row justify-between">
        <div class="grid_1 flex-row">
          <div
            class="list-items_2 flex-col"
            :style="{ background: item.lanhuBg0 }"
            v-for="(item, index) in loopData1"
            :key="index"
          >
            <div class="section_10 flex-row">
              <div class="text-group_28 flex-col justify-between">
                <span class="text_55" v-html="item.lanhutext0"></span>
                <span class="text_56" v-html="item.lanhutext1"></span>
              </div>
            </div>
          </div>
        </div>
        <img
          class="image_23"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/home/SketchPng22e3a90e6ca7b13c2195febdf0aac6b62c013a2efd20896a777ae361adc026d6.png"
        />
      </div>
    </div>

    <CommonFooter />
  </div>
</template>
<script>
import CommonMenuBar from '../common/menu_bar/index.vue';
import CommonFooter from '../common/footer/index.vue';
export default {
  components:{CommonMenuBar, CommonFooter},
  data() {
    return {
      loopData0: [
        { lanhutext0: '商标权', slot1: 1 },
        { lanhutext0: '专利权', slot3: 3 },
        {
          lanhutext0: '版权',
          specialSlot2: {
            lanhuimage0:
              'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng80d619741a0eab744e8c48d5d5d7c62a9ca4b94ba83847647047f6e5680af54e'
          },
          slot2: 2
        },
        {
          lanhutext0: '不正当竞争',
          specialSlot2: {
            lanhuimage0:
              'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng78a75669ffb6fb2d158018d5e2ab73c8f103f9147f69d4bff962b7168af2f417'
          },
          slot2: 2
        }
      ],
      loopData1: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/82100a6f4be44b01a3244a28dbfaf5a4_mergeImage.png)',
          lanhutext0: '知识产权维权',
          lanhutext1: '·&nbsp;线上、线下全渠道维权<br/>·&nbsp;系统智能化案件管理<br/>·&nbsp;全国律师协同办案'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a468945964d74dc48ad87aef2a6cec13_mergeImage.png)',
          lanhutext0: '电商控价维权',
          lanhutext1:
            '·&nbsp;淘宝、拼多多、天猫、京东等电商平台价格监控<br/>·&nbsp;违规链接下架、违规店铺监控<br/>·&nbsp;窜货产品采买，渠道秩序管理'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/626724d694ca4b259968633c03a27aa3_mergeImage.png)',
          lanhutext0: '民事调查取证',
          lanhutext1: '·&nbsp;线上采集软件加持<br/>·&nbsp;线下专业团队调查<br/>·&nbsp;主体准确，诉讼无忧'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d87e08ad375d42abaf42df24b039a9a1_mergeImage.png)',
          lanhutext0: '工商投诉、刑事调查、海关扣柜',
          lanhutext1:
            '·&nbsp;调查侵权产品目标，发起工商投诉<br/>·&nbsp;调查制假工厂，锁定假货目标，发起刑事立案<br/>·&nbsp;搜集海关扣柜要的初步线索，'
        }
      ],
      constants: {}
    };
  },
  methods: {
    gotoLogin(role){
      role = role||'LawyerTeam'
      window.open(`http://platform.anyuanip.com/?role=${role}`,'_blank')
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />