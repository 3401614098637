<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <CommonMenuBar theme="black"/>

      <div class="image-wrapper_1 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/team/SketchPngf8c711814b2e5829bb67f4de5be42a8555c80aa5ec638d93d014aefa89a84034.png"
        />
      </div>
      <div class="text-wrapper_1 flex-row"><span class="text_8">专业团队</span></div>
      <div class="text-wrapper_2 flex-row">
        <span class="text_9">我们希望维权团队可以在案元网轻松地接案件，办案件</span>
      </div>
      <div class="group_4 flex-row">
        <div class="text-wrapper_3 flex-col"><span class="text_10">加入我们</span></div>
      </div>
    </div>
    <div class="box_3 flex-col">
      <span class="text_11">律师团队</span>
      <div class="grid_1 flex-row">
        <div class="group_5 flex-col"></div>
        <div class="group_6 flex-col"></div>
        <div class="group_7 flex-col"></div>
        <div class="group_8 flex-col">
          <div class="text-wrapper_4 flex-row justify-between">
            <span class="text_12">浙江-杭州</span>
            <span class="text_13">张丽艳</span>
          </div>
          <div class="group_9 flex-col"></div>
          <span class="paragraph_1">
            案元网·北京盈科（杭州）律师事务所，颐坤团队负责人
            <br />
          </span>
          <span class="text_14">
            张律师为双证律师（执业律师、执业专利代理师），多年从事知识产权案件维权，曾多起代理重大（50万以上）侵权案件的原告诉讼工作。对重大侵权案件的研究，维权有独到的维权思路。
          </span>
        </div>
        <div class="group_10 flex-col">
          <div class="text-wrapper_5 flex-row justify-between">
            <span class="text_15">浙江-杭州</span>
            <span class="text_16">崔杨菁</span>
          </div>
          <div class="group_11 flex-col"></div>
          <div class="text-group_1 flex-col justify-between">
            <span class="text_17">案元网·杭州正牧律师事务所</span>
            <span class="text_18">
              山东大学法学院、重庆大学机械设计及其自动化专业，双学士，济南市律师协会理事，知识产权委员会委员；曾代理多起知识产权案件，被最高院、山东省高院、济南中院等评为年度十大、五十大知识产权案件。
            </span>
          </div>
        </div>
        <div class="group_12 flex-col">
          <div class="text-wrapper_6 flex-row justify-between">
            <span class="text_19">浙江-杭州</span>
            <span class="text_20">何婧</span>
          </div>
          <div class="group_13 flex-col"></div>
          <div class="text-group_2 flex-col justify-between">
            <span class="text_21">案元网·北京高朋(杭州)律师事务所</span>
            <span class="text_22">
              何律师毕业于北京邮电硕士研究生，具有十年的民事诉讼业务经验，代理的客户包括中国工商银行、中交集团等大型国企，也具有互联网头部企业的法律服务经验，熟悉互联网基础业务逻辑和法律研究，具有数据、信息等前沿法律研究经历
            </span>
          </div>
        </div>
        <div class="group_14 flex-col"></div>
        <div class="group_15 flex-col"></div>
        <div class="group_16 flex-col"></div>
        <div class="group_17 flex-col">
          <div class="text-wrapper_7 flex-row justify-between">
            <span class="text_23">新疆-乌鲁木齐</span>
            <span class="text_24">王嘉懿</span>
          </div>
          <div class="group_18 flex-col"></div>
          <div class="text-group_3 flex-col justify-between">
            <span class="text_25">案元网·新疆德恒律师事务所</span>
            <span class="text_26">
              华侨大学法学、经济学双学士，爱尔兰都柏林大学国际商法硕士。现为北京德恒（乌鲁木齐）律师事务所执业律师、知识产权专业委员会副主任，新疆维吾尔自治区三级律师。专业领域：商标侵权、专利侵权案件及不正当竞争。
            </span>
          </div>
        </div>
        <div class="group_19 flex-col">
          <div class="text-wrapper_8 flex-row justify-between">
            <span class="text_27">江苏-徐州</span>
            <span class="text_28">董浩</span>
          </div>
          <div class="box_4 flex-col"></div>
          <div class="text-group_4 flex-col justify-between">
            <span class="text_29">案元网·江苏智临律师事务所&nbsp;</span>
            <span class="text_30">
              董浩律师为双证律师（执业律师、执业专利代理师），曾就职于国家知识产权局审协江苏中心、北京大成（上海）律师事务所等。自2013年开始从事知识产权相关工作，主要执业领域为:知识产权诉讼、专利检索分析、专利无效、专利申请等，在知识产权保护和争议解决方面拥有丰富的经验。
            </span>
          </div>
        </div>
        <div class="group_20 flex-col">
          <div class="text-wrapper_9 flex-row justify-between">
            <span class="text_31">山东-临沂</span>
            <span class="text_32">孙绍亮</span>
          </div>
          <div class="box_5 flex-col"></div>
          <div class="text-group_5 flex-col justify-between">
            <span class="text_33">案元网·山东奥正律师事务所</span>
            <span class="text_34">
              法学专业，具有扎实的法学专业知识和丰富的办案经验，从业十余年以，长期从事商标权、著作权、外观设计专利权等知产案件纠纷的办理，并在婚姻家庭纠纷、劳动工伤纠纷、经济合同纠纷、债权债务纠纷等案件中具有丰富经验，孙律师秉持“专业勤勉、良知操守”的法律精神
            </span>
          </div>
        </div>
      </div>
      <div class="text-wrapper_10 flex-col"><span class="text_35">加入我们</span></div>
    </div>
    <div class="box_6 flex-col">
      <span class="text_36">调查团队</span>
      <div class="group_21 flex-row justify-between">
        <div class="box_7 flex-row">
          <div class="text-group_6 flex-col justify-between">
            <span class="text_37">自营调查团队</span>
            <span class="text_38">调查能力强，行动速度快，经验丰富</span>
          </div>
          <div class="group_22 flex-row">
            <div class="text-group_7 flex-col justify-between">
              <span class="text_39">合作调查团队</span>
              <span class="text_40">多团队合作，可快速全面铺开调查</span>
            </div>
          </div>
        </div>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/team/SketchPng8bf63a2a56455180980f0690019a97277b2d3f0aa072df1a3315b53a9032528b.png"
        />
      </div>
      <div class="text-wrapper_11 flex-col"><span class="text_41">加入我们</span></div>
    </div>
    <div class="box_8 flex-col">
      <div class="group_23 flex-row justify-between">
        <div class="section_1 flex-col">
          <div class="block_1 flex-row justify-between">
            <div class="block_2 flex-col">
              <div class="text-wrapper_12 flex-col"><span class="text_42">新疆</span></div>
            </div>
            <div class="block_3 flex-col">
              <div class="section_2 flex-col">
                <div class="text-wrapper_13 flex-col"><span class="text_43">河北</span></div>
                <div class="text-wrapper_14 flex-col"><span class="text_44">山东</span></div>
              </div>
              <div class="section_3 flex-row justify-between">
                <div class="group_24 flex-col">
                  <div class="text-wrapper_15 flex-col"><span class="text_45">河南</span></div>
                  <img
                    class="image_4"
                    referrerpolicy="no-referrer"
                    src="http://qiniu.anyuanip.com/home/team/SketchPng1208f06a7346674d08161d556eb474ba1a0a359e3b6676fb2304b51e9cb4f1cd.png"
                  />
                  <div class="text-wrapper_16 flex-col"><span class="text_46">湖北</span></div>
                </div>
                <div class="group_25 flex-col justify-between">
                  <div class="text-wrapper_17 flex-col"><span class="text_47">江苏</span></div>
                  <div class="image-wrapper_2 flex-col">
                    <img
                      class="thumbnail_2"
                      referrerpolicy="no-referrer"
                      src="http://qiniu.anyuanip.com/home/team/SketchPng1d56b7f7949c1cf174a88e620d3220b190315a75450a11cfb1c975549573950c.png"
                    />
                  </div>
                </div>
              </div>
              <div class="text-wrapper_18 flex-col"><span class="text_48">浙江</span></div>
              <div class="section_4 flex-row justify-between">
                <div class="text-wrapper_19 flex-col"><span class="text_49">湖南</span></div>
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/team/SketchPng7e92987a368649779c61a913e3c314f73905c950f73a1aa388909e43a103d21b.png"
                />
              </div>
              <div class="section_5 flex-col">
                <div class="text-wrapper_20 flex-col"><span class="text_50">江西</span></div>
                <img
                  class="image_6"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/team/SketchPng01398080998439d0e9b6bf2a0b19436ee221a57c75f52f784e8380f83bc6f8ea.png"
                />
                <div class="text-wrapper_21 flex-col"><span class="text_51">福建</span></div>
              </div>
              <div class="section_6 flex-col">
                <div class="group_26 flex-col">
                  <div class="text-wrapper_22 flex-col"><span class="text_52">安徽</span></div>
                </div>
              </div>
              <div class="text-wrapper_23 flex-col"><span class="text_53">上海</span></div>
            </div>
          </div>
          <div class="block_4 flex-row">
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/team/SketchPng7c2043b8eb4c93e482c9b233d2fe1e6e5efdad84d1d959358558f4815c6e8a9b.png"
            />
            <div class="text-wrapper_24 flex-col"><span class="text_54">海南</span></div>
          </div>
        </div>
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/team/SketchPngccbc0e4ddc78ea6c6720d55a9970a0a2e2e07ade5759037bc06d584afa4979aa.png"
        />
      </div>
      <div class="group_27 flex-row">
        <div class="text-wrapper_25">
          <span class="text_55">案元科技与</span>
          <span class="text_56">杭州、江西、山东、江苏、河南、福建、新疆</span>
          <span class="text_57">
            等多家公证处合作，公证区域业务范围涉及到网络买货、实体店买货、门头公证、工厂调查取证等业务，可做到快速出动，快速取证
          </span>
        </div>
      </div>
      <div class="text-wrapper_26 flex-row"><span class="text_58">公证合作</span></div>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="http://qiniu.anyuanip.com/home/team/SketchPngbc424d180a8c5e77cfffd9248dbf59ebae793c1c304e01bac6ea84159cf95ce2.png"
      />
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="http://qiniu.anyuanip.com/home/team/SketchPng1e1d746f845406afa6d5d95fd1dda228e4111da00755e52036f508bcdf5f50c5.png"
      />
    </div>
    <div class="box_9 flex-col">
      <span class="text_59">调解合作</span>
      <div class="block_5 flex-col"></div>
      <span class="text_60">案元科技与行业内专业的调解机构合作，可在正式立案前调解消化一部分案件。</span>
    </div>

    <CommonFooter />
  </div>
</template>
<script>
import CommonMenuBar from '../common/menu_bar/index.vue';
import CommonFooter from '../common/footer';
export default {
  components:{CommonMenuBar, CommonFooter},
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />