<template>
  <div class="menu-bar flex-row">
    <template v-if="theme=='black'">
      <img
        class="logo"
        referrerpolicy="no-referrer"
        src="./assets/logo_black.png"
      />
    </template>
    <template v-else>
      <img
        class="logo"
        referrerpolicy="no-referrer"
        src="./assets/logo_white.png"
      />
    </template>


    <div class="flex-row">
      <div v-for="(v,i) in tabs" :key="`tab_${i}`" class="menu-tab flex-col justify-between">
        <a :class="{'menu-text':true,'active':activeIndex==i, white:theme!='black'}" :href="`/${v.route.name}`">{{ v.name }}</a>
        <div class="box_2 flex-col" v-if="activeIndex==i"></div>
      </div>
    </div>

    
    <div class="box_3 flex-row justify-center align-center">
      <a class="text_7" href="http://platform.anyuanip.com/?role=Administrator">系统登录</a>
      <img
        class="thumbnail_1"
        referrerpolicy="no-referrer"
        src="./assets/SketchPng48ee4f62bef7e232d7c6c261b51719d541a0fa8736806e5d94827d66fb7cc460.png"
      />
    </div>
  </div>
</template>

<script>

export default {
  name:'CommonMenuBar',
  props:{
    theme:{
      type:String,
      default:'black'
    }
  },
  data(){
    return {
      tabs:[
        {
          name:'公司首页',
          route:{name:'home'}
        },
        {
          name:'侵权诉讼',
          route:{name:'legalaction'}
        },
        {
          name:'网络控价',
          route:{name:'pricemonitoring'}
        },
        {
          name:'线索调查',
          route:{name:'investigation'}
        },
        {
          name:'专业团队',
          route:{name:'team'}
        },
        {
          name:'关于我们',
          route:{name:'aboutus'}
        }
      ]
    }
  },
  mounted(){
    
  },
  computed:{
    activeIndex(){
      for(let i=0;i<this.tabs.length; ++i){
        if(this.tabs[i].route.name==this.$route.name) return i
      }
      return -1
    }
  }
}
</script>

<style scoped>
.menu-bar {
  margin: 40px 56px 0 40px;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 150px;
    height: 44px;
  }

  .menu-tab{
    &:not(:last-child){
      margin-right:80px
    }
  }

  .box_2 {
    background-color: rgba(0, 104, 255, 1);
    border-radius: 2px;
    width: 20px;
    height: 3px;
    margin: 8px 0 0 22px;
  }

  .box_3 {
    background-color: rgba(0, 104, 255, 1);
    border-radius: 20px;
    width: 122px;
    height: 40px;
  }

  .menu-text {
    cursor: pointer;
    width: 64px;
    height: 16px;
    overflow-wrap: break-word;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    line-height: 24px;
    color: rgba(26, 26, 26, 1);
    font-family: SourceHanSansCN-Normal;

    &.active{
      color: rgba(0, 104, 255, 1)!important;
      font-family: SourceHanSansCN-Medium;
    }

    &.white{
      color:#fff;
    }
  }

  .text_7 {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-family: SourceHanSansCN-Normal;
    text-align: left;
    white-space: nowrap;
    line-height: 40px;
  }

  .thumbnail_1 {
    cursor: pointer;
    margin-left:7px;
    width: 12px;
    height: 12px;
  }
}

</style>