<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <CommonMenuBar theme="white"/>

      <div class="image-wrapper_4 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/legalaction/SketchPng5b6ac92a2e0e6410e67ac13ecc15f79aed59fa55850a90b97828e811f2494c41.png"
        />
      </div>
      <div class="text-wrapper_8 flex-row"><span class="text_8">品牌方的好帮手</span></div>
      <div class="text-wrapper_9 flex-row"><span class="text_9">侵权诉讼、网络控价、线索调查</span></div>
    </div>
    <div class="group_24 flex-col">
      <div class="box_2 flex-col">
        <span class="text_10">产品特点</span>
        <div class="list_2 flex-row">
          <div
            class="list-items_1 flex-col"
            :style="{ background: item.lanhuBg0 }"
            v-for="(item, index) in loopData0"
            :key="index"
          >
            <div class="group_5 flex-row">
              <div class="text-group_17 flex-col justify-between">
                <span class="text_11" v-html="item.lanhutext0"></span>
                <span class="text_12" v-html="item.lanhutext1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box_3 flex-col justify-end">
        <span class="text_13">可帮到权利人</span>
        <div class="section_2 flex-row justify-between">
          <div class="box_4 flex-row">
            <div class="text-group_18 flex-col justify-between">
              <span class="text_14">快速</span>
              <span class="paragraph_1">
                快速调查、快速取证、
                <br />
                快速立案、快速产生打击效果
              </span>
            </div>
          </div>
          <div class="box_5 flex-row">
            <div class="text-group_18 flex-col justify-between">
              <span class="text_14">全面</span>
              <span class="paragraph_1">
                全范围打击，&nbsp;线上各渠道，
                <br />
                线下各省份，同步启动
              </span>
            </div>
          </div>
          <div class="box_6 flex-row">
            <div class="text-group_18 flex-col justify-between">
              <span class="text_14">重拳</span>
              <span class="paragraph_1">
                重要案件专门设计维权方案，
                <br />
                让违法者付出沉重的代价
              </span>
            </div>
          </div>
          <div class="box_7 flex-row">
            <div class="text-group_18 flex-col justify-between">
              <span class="text_14">透明</span>
              <span class="paragraph_1">
                所有案件上系统，
                <br />
                案件信息一目了然
              </span>
            </div>
          </div>
        </div>
        <span class="text_18">Assist</span>
        <div class="image-wrapper_5 flex-row justify-between">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/legalaction/SketchPng785b3ec7955751e5982d1903cf43016c4bdc33d3773f99f42e655ce1fb03c6a7.png"
          />
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/legalaction/SketchPng2ebc2d509a3713cf98a0b6d4af68b188919c5236ddbc1fcc97091a69b9f2e109.png"
          />
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/legalaction/SketchPng37307c93899d058a9aa3876d5eff656aef2b0777bdbdd59cebd22c9d3fab4028.png"
          />
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/legalaction/SketchPng277a78913229da4a0bf156ea2df5101317766ee38a10744f2e552dbcb0e764af.png"
          />
        </div>
      </div>
    </div>
    <div class="group_7 flex-col">
      <span class="text_19">线索调查</span>
      <div class="grid_3 flex-row">
        <div
          class="list-items_2 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData1"
          :key="index"
        >
          <span v-if="item.slot1 === 1" class="text_20" v-html="item.specialSlot1.lanhutext0"></span>
          <div v-if="item.slot3 === 3" class="group_8 flex-col"></div>
          <span v-if="item.slot2 === 2" class="text_21" v-html="item.specialSlot2.lanhutext0"></span>
        </div>
      </div>
    </div>
    <div class="group_25 flex-col">
      <div class="group_10 flex-col">
        <span class="text_22">案件风险把控</span>
        <div class="grid_4 flex-row">
          <div class="image-text_1 flex-row">
            <div class="text-group_22 flex-col justify-between">
              <span class="text_23">主体准确</span>
              <span class="text_24">&nbsp;主体查重、工商网检索、注销核查</span>
            </div>
            <img
              class="image_7"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/legalaction/SketchPng914cd521aeb57abc9efe34a0dd328443900503dd9f9d7a696ea59be00303bfa3.png"
            />
          </div>
          <div class="group_11 flex-col">
            <div class="section_3 flex-row justify-between">
              <span class="text_25">证据真假审核</span>
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/legalaction/SketchPnga0d6b1150f4ed8d7cb6d96832252a08497f9bf09af183c0845f8963553d1d4b6.png"
              />
            </div>
            <span class="paragraph_5">
              两道审核，调查审核+公证实物审核，
              <br />
              确保公证购买的物品100%侵权
            </span>
          </div>
          <div class="group_12 flex-row">
            <div class="text-group_23 flex-col justify-between">
              <span class="text_26">调查标准认真执行</span>
              <span class="text_27">根据品牌方要求执行调查标准</span>
            </div>
            <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/legalaction/SketchPng53a43e07e81c4441326d5bf3e9ae5673b93dbb91656398fba3456b16e2667757.png"
            />
          </div>
          <div class="group_13 flex-row">
            <div class="text-group_24 flex-col justify-between">
              <span class="text_28">诉讼方案审核</span>
              <span class="paragraph_6">
                律师的诉讼方案品牌方审核
                <br />
                通过后方可立案
              </span>
            </div>
            <img
              class="image_10"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/legalaction/SketchPng10d0760f53a249ce2067be03dd6460f5af6627f8a302059266310ed5fdbf7ae5.png"
            />
          </div>
          <div class="image-text_2 flex-row">
            <div class="text-group_25 flex-col justify-between">
              <span class="text_29">办案过程透明</span>
              <span class="text_30">案件每个步骤各个端口可同步查看</span>
            </div>
            <img
              class="image_11"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/legalaction/SketchPng993eebb2b7b8c18ab4459ace0c1854a528339763a0656549c168cb1b52b05eb2.png"
            />
          </div>
          <div class="group_14 flex-row">
            <div class="text-group_26 flex-col justify-between">
              <span class="text_31">结算清晰</span>
              <span class="text_32">系统自动核算，账务一键查看</span>
            </div>
            <img
              class="image_12"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/legalaction/SketchPng557483afcdeb886181321e3119e23a861bebb1d435de0fce8bd6d496095bc1d8.png"
            />
          </div>
        </div>
      </div>
      <div class="group_15 flex-col">
        <div class="group_26 flex-row">
          <div class="text-group_27 flex-col justify-between">
            <span class="text_33">重点客户案例</span>
            <span class="text_34">公司业务涉及国内外知名品牌</span>
          </div>
        </div>
        <div class="group_27 flex-row justify-between">
          <div class="block_5 flex-col">
            <div class="text-group_28 flex-col justify-between">
              <span class="text_35">商标案件</span>
              <span class="text_36">南孚电池为国内碱性电池遥遥领先者</span>
            </div>
            <div class="group_16 flex-col"></div>
          </div>
          <div class="block_6 flex-col">
            <div class="text-group_29 flex-col justify-between">
              <span class="text_37">专利案件</span>
              <span class="text_38">骑客平衡车专利入选最高院十大经典案例</span>
            </div>
            <div class="box_8 flex-col"></div>
          </div>
          <div class="block_7 flex-col">
            <div class="text-group_30 flex-col justify-between">
              <span class="text_39">国际客户</span>
              <span class="text_40">协助日本知名品牌在中国本土落地维权</span>
            </div>
            <div class="group_17 flex-col"></div>
          </div>
        </div>
      </div>
    </div>

    <CommonFooter />
  </div>
</template>
<script>
import CommonMenuBar from '../common/menu_bar/index.vue';
import CommonFooter from '../common/footer/index.vue';
export default {
  components:{CommonMenuBar, CommonFooter},
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/56094a5538b54cc8be8ccf923faf66fc_mergeImage.png)',
          lanhutext0: '成本低',
          lanhutext1: '全风险合作<br/>零成本维权<br/>享受维权收益'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/7d7fb99ceaa3421ca42113d15b1b6434_mergeImage.png)',
          lanhutext0: '团队强',
          lanhutext1: '优秀的律师团队<br/>专业的调查团队<br/>高效的公证团队'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/294279a236a640a1a8360c852c62611b_mergeImage.png)',
          lanhutext0: '智能化',
          lanhutext1: '线索固证智能化<br/>案件管理系统化<br/>费用结算清晰'
        }
      ],
      loopData1: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/2ddc29f45d0b42c887873d789c976dc3_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5c4a361450f64398acf2a6e4bd379b11_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/39be9ea5a55b49dabc59228e8a72ee60_mergeImage.png)'
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '线上侵权调查' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '电商平台，本地生活，直播平台全网络覆盖' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '实体店铺调查取证' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '专业团队线下调查，快速锁定侵权店铺' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '工厂调查' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '摸清工厂生产细节，确定产品内部结构' },
          slot2: 2
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5f7af51d092f410b8544fcd8bf194e1c_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/fba1dc804a644421a3063ddaa4e82d9c_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/192d02c00d7e4c20992d70a1e696faa6_mergeImage.png)'
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '海关扣柜' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '装柜照片、产品照片、货柜号、车牌号确定初步线索' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '刑事跟踪' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '通过初步线索，摸清制假窝点和侵权数量' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '行政查处' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '查到货源所在地，直接向当地行政部门发起投诉' },
          slot2: 2
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />