<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <CommonMenuBar theme="black"/>

      <div class="box_2 flex-row justify-between">
        <div class="image-wrapper_1 flex-col">
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPng9fa159e9d3c109c5305769628d2e21f97f283ef5ae014ea3383046bb19c77b01.png"
          />
        </div>
        <div class="box_3 flex-col"></div>
        <div class="text-wrapper_1 flex-col justify-between">
          <span class="text_8">品牌方的好帮手</span>
          <span class="text_9">侵权诉讼、网络控价、线索调查</span>
          <span class="text_10">品牌方的好帮手</span>
          <span class="text_11">侵权诉讼、网络控价、线索调查</span>
        </div>
      </div>
      <div class="box_4 flex-row"><div class="box_5 flex-col"></div></div>
    </div>
    <div class="block_2 flex-col">
      <div class="box_6 flex-col">
        <div class="text-wrapper_2 flex-row"><span class="text_12">可监控平台</span></div>
        <div class="box_7 flex-row justify-between">
          <div class="group_4 flex-col"></div>
          <div class="group_5 flex-col"></div>
          <div class="group_6 flex-col"></div>
          <div class="group_7 flex-col"></div>
          <div class="group_8 flex-col"></div>
          <div class="group_9 flex-col"></div>
          <div class="group_10 flex-col"></div>
        </div>
        <div class="box_8 flex-row justify-between">
          <div class="group_11 flex-col"></div>
          <div class="group_12 flex-col"></div>
          <div class="group_13 flex-col"></div>
          <div class="group_14 flex-col"></div>
          <div class="group_15 flex-col"></div>
          <div class="group_16 flex-col"></div>
          <div class="group_17 flex-col"></div>
        </div>
        <div class="box_9 flex-row justify-between">
          <div class="group_18 flex-col"></div>
          <div class="group_19 flex-col"></div>
          <div class="group_20 flex-col"></div>
          <div class="group_21 flex-col"></div>
          <div class="group_22 flex-col"></div>
        </div>
      </div>
      <div class="box_10 flex-col">
        <div class="group_23 flex-col">
          <span class="text_13">产品特点</span>
          <div class="list_1 flex-row">
            <div
              class="list-items_1 flex-col"
              :style="{ background: item.lanhuBg0 }"
              v-for="(item, index) in loopData0"
              :key="index"
            ></div>
          </div>
          <div class="section_1 flex-row">
            <div class="text-group_1 flex-col justify-between">
              <span class="text_14">价格监控全面</span>
              <span class="text_15">电商平台全覆盖监控</span>
            </div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_16">维权经验丰富</span>
              <span class="text_17">8年维权经验，熟悉各平台删链接标准</span>
            </div>
            <div class="text-group_3 flex-col justify-between">
              <span class="text_18">链接下架迅速</span>
              <span class="text_19">核心平台1~3个工作日下架链接</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_11 flex-col">
        <span class="text_20">服务内容</span>
        <div class="grid_1 flex-row">
          <div class="block_3 flex-col">
            <div class="group_24 flex-row justify-between">
              <img
                class="label_1"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPng7f8a33b495e693661d983c609d8376aee26b43ed5fcc4b92cc26e9ec9e1bd534.png"
              />
              <span class="text_21">价格监控</span>
            </div>
            <span class="text_22">页面低价、到手价、开票低价均可监控</span>
          </div>
          <div class="block_4 flex-col">
            <div class="block_5 flex-row justify-between">
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPng66507395ce3e56392f76c343c06bca791059f46fed07352cf3eb3ecff7b9c334.png"
              />
              <span class="text_23">链接删除</span>
            </div>
            <span class="paragraph_1">
              通过侵权判断，找出链接侵权点，直接
              <br />
              投诉下架
            </span>
          </div>
          <div class="block_6 flex-col">
            <div class="box_12 flex-row justify-between">
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPngcd740853a8cf7113f438a9f0bb555ef27d09e8f811a6cab64d1a0447d76f0b19.png"
              />
              <span class="text_24">购买鉴定</span>
            </div>
            <span class="text_25">根据品牌方要求执行调查标准</span>
          </div>
          <div class="block_7 flex-col">
            <div class="box_13 flex-row justify-between">
              <div class="text-wrapper_3 flex-col"><span class="text_26">￥</span></div>
              <span class="text_27">通知改价</span>
            </div>
            <span class="paragraph_2">
              对于授权店铺，可通知其修改价格，不配合
              <br />
              修改的记录备案
            </span>
          </div>
          <div class="block_8 flex-col">
            <div class="block_9 flex-row justify-between">
              <img
                class="label_4"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPng10eccbef6f56b283cd96b1ae7b961e56ad1fd9ad14c618b30cd80816afece982.png"
              />
              <span class="text_28">招安收编</span>
            </div>
            <span class="paragraph_3">
              对符合要求的非授权店铺，可沟通纳入授权
              <br />
              编制内
            </span>
          </div>
          <div class="block_10 flex-col">
            <div class="group_25 flex-row justify-between">
              <img
                class="label_5"
                referrerpolicy="no-referrer"
                src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPng2727bda7b78457fd76431fa3a833e1d342d4fee4acb3b1ba7590da108cac0f4f.png"
              />
              <span class="text_29">经销商管理</span>
            </div>
            <span class="paragraph_4">
              定期对授权店铺经营评分，协助品牌方考核
              <br />
              经销商
            </span>
          </div>
        </div>
      </div>
      <div class="text-group_4 flex-col justify-between">
        <span class="text_30">重点客户案例</span>
        <span class="text_31">公司业务涉及国内外知名品牌</span>
      </div>
      <div class="list_2 flex-row">
        <div
          class="list-items_2 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData1"
          :key="index"
        >
          <div class="text-group_5 flex-col justify-between">
            <span class="text_32" v-html="item.lanhutext0"></span>
            <span class="text_33" v-html="item.lanhutext1"></span>
          </div>
          <div class="image-wrapper_2 flex-col" :style="{ background: item.lanhuBg2 }">
            <img
              v-if="item.slot1 === 1"
              class="image_3"
              referrerpolicy="no-referrer"
              src="http://qiniu.anyuanip.com/home/pricemonitoring/SketchPngac22dce163e082c16e8714245cbfe8630309e5b10e509b56a599fcd95a24b2e0.png"
            />
          </div>
        </div>
      </div>
    </div>

    <CommonFooter />
  </div>
</template>
<script>
import CommonMenuBar from '../common/menu_bar/index.vue';
import CommonFooter from '../common/footer/index.vue';
export default {
  components:{CommonFooter, CommonMenuBar},
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f8bd3f60da2b4c658a9bb23a22b831b1_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/b20503531a2f41e7a8122eefffd5b21a_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/7dc1b98d697e4b86aea2b49d91d97487_mergeImage.png)'
        }
      ],
      loopData1: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/fbbfec02dd384b51a71675451376a06e_mergeImage.png)',
          lanhutext0: '生活电器',
          lanhutext1: '超人科技为国内生活电器领域知名品品牌',
          lanhuBg2:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/da5ca61528f141908f911f73de489dcb_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/6826da0454934c7bba1d7ad8582c5006_mergeImage.png)',
          lanhutext0: '培训行业',
          lanhutext1: '全球知名儿童早教品牌',
          lanhuBg2:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/1c697848762846fa9707c7fbd943feb9_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/eea30ca882084861bd0eca195756d905_mergeImage.png)',
          lanhutext0: '服装鞋类',
          lanhutext1: '协助知名童装品牌管理渠道秩序',
          lanhuBg2:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng21688623804a84b358d6397205dc07126c2491b24dfdb967532f0764575c7284) 100% no-repeat',
          slot1: 1
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />