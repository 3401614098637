<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <CommonMenuBar theme="black"/>

      <div class="group_5 flex-row justify-between">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/investigation/SketchPng9fa159e9d3c109c5305769628d2e21f97f283ef5ae014ea3383046bb19c77b01.png"
        />
        <div class="group_6 flex-col"></div>
        <div class="text-wrapper_1 flex-col justify-between">
          <span class="text_8">品牌方的好帮手</span>
          <span class="text_9">侵权诉讼、网络控价、线索调查</span>
        </div>
      </div>
    </div>
    <div class="group_7 flex-col">
      <div class="group_8 flex-col">
        <span class="text_10">产品特点</span>
        <div class="list_1 flex-row">
          <div class="list-items_1 flex-col" v-for="(item, index) in loopData0" :key="index">
            <img class="image_3" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
            <div class="text-group_1 flex-col justify-between">
              <span class="text_11" v-html="item.lanhutext0"></span>
              <span class="text_12" v-html="item.lanhutext1"></span>
            </div>
          </div>
        </div>
        <!--div class="text-wrapper_2 flex-row justify-between">
          <span class="text_12">调查快，取证快，出公证书快</span>
          <span class="text_13">确保侵权主体准确，不准确不出证</span>
          <span class="text_14">线上、线下全覆盖调查打击</span>
        </div-->
      </div>
      <span class="text_15">调查团队</span>
      <div class="group_9 flex-row justify-between">
        <div class="group_10 flex-row">
          <div class="text-group_2 flex-col justify-between">
            <span class="text_16">自营调查团队</span>
            <span class="text_17">调查能力强，行动速度快，经验丰富</span>
          </div>
          <div class="section_2 flex-row">
            <div class="text-group_3 flex-col justify-between">
              <span class="text_18">合作调查团队</span>
              <span class="text_19">多团队合作，可快速全面铺开调查</span>
            </div>
          </div>
        </div>
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/investigation/SketchPng8bf63a2a56455180980f0690019a97277b2d3f0aa072df1a3315b53a9032528b.png"
        />
      </div>
    </div>
    <div class="group_11 flex-col">
      <span class="text_20">线索调查</span>
      <div class="grid_1 flex-row">
        <div
          class="list-items_2 flex-col"
          :style="{ background: item.lanhuBg0 }"
          v-for="(item, index) in loopData1"
          :key="index"
        >
          <span v-if="item.slot1 === 1" class="text_21" v-html="item.specialSlot1.lanhutext0"></span>
          <div v-if="item.slot3 === 3" class="block_1 flex-col"></div>
          <span v-if="item.slot2 === 2" class="text_22" v-html="item.specialSlot2.lanhutext0"></span>
        </div>
      </div>
    </div>
    <div class="group_12 flex-col">
      <div class="box_1 flex-col">
        <span class="text_23">调查能力·三快</span>
        <div class="list_2 flex-row">
          <div
            class="list-items_3 flex-col"
            :style="{ background: item.lanhuBg0 }"
            v-for="(item, index) in loopData2"
            :key="index"
          >
            <div class="text-wrapper_3"><span class="text_24" v-html="item.lanhutext0"></span></div>
            <span class="text_25" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <div class="text-group_4 flex-col justify-between">
        <span class="text_26">重点客户案例</span>
        <span class="text_27">公司业务涉及国内知名品牌</span>
      </div>
      <div class="box_2 flex-row justify-between">
        <div class="group_13 flex-row">
          <div class="text-group_5 flex-col justify-between">
            <span class="text_28">民事侵权调查</span>
            <span class="text_29">周黑鸭门头侵权调查</span>
          </div>
        </div>
        <div class="group_14 flex-row">
          <div class="text-group_6 flex-col justify-between">
            <span class="text_30">刑事调查</span>
            <span class="text_31">某吊顶品牌，通过门面店铺挖掘到生产工厂</span>
          </div>
        </div>
        <div class="group_15 flex-row">
          <div class="text-group_7 flex-col justify-between">
            <span class="text_32">海关扣柜</span>
            <span class="text_33">某知名平衡车专利权利人，调查工厂海关扣柜</span>
          </div>
        </div>
      </div>
    </div>

    <CommonFooter />
  </div>
</template>
<script>
import CommonMenuBar from '../common/menu_bar/index.vue';
import CommonFooter from '../common/footer/index.vue';
export default {
  components:{CommonMenuBar, CommonFooter},
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng785b3ec7955751e5982d1903cf43016c4bdc33d3773f99f42e655ce1fb03c6a7',
          lanhutext0: '速度快',
          lanhutext1: '调查快，取证快，出公证书快'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng8c0fdbc11072f4d53708ffc55107871ef5115c405dd367a769fbb7705cd94dd6',
          lanhutext0: '主体准',
          lanhutext1: '确保侵权主体准确，不准确不出证'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng2ebc2d509a3713cf98a0b6d4af68b188919c5236ddbc1fcc97091a69b9f2e109',
          lanhutext0: '覆盖全',
          lanhutext1: '线上、线下全覆盖调查打击'
        }
      ],
      loopData1: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/eff5815391f640d7a1b74f2ca5a6b025_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/2d102a3f77744ce791d1e1719b27873c_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/b8ad282df85440968b3a18e2e4244ab3_mergeImage.png)'
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '线上侵权调查' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '电商平台，本地生活，直播平台全网络覆盖' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '实体店铺调查取证' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '专业团队线下调查，快速锁定侵权店铺' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '工厂调查' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '摸清工厂生产细节，确定产品内部结构' },
          slot2: 2
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/5f5a6dc61feb41c396ae07e690a002aa_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/34810afe11a0447d97bd6f432da84d92_mergeImage.png)'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3ead8e31dfbb46a4a5be0a52fc4c7583_mergeImage.png)'
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '海关扣柜' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '装柜照片、产品照片、货柜号、车牌号确定初步线索' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '刑事跟踪' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '通过初步线索，摸清制假窝点和侵权数量' },
          slot2: 2
        },
        {
          lanhuBg0: 'rgba(255,255,255,1.000000)',
          specialSlot1: { lanhutext0: '行政查处' },
          slot1: 1,
          specialSlot3: {},
          slot3: 3,
          specialSlot2: { lanhutext0: '查到货源所在地，直接向当地行政部门发起投诉' },
          slot2: 2
        }
      ],
      loopData2: [
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/032e75811b294b2980339c2d655fdc52_mergeImage.png) 100% no-repeat',
          lanhutext0: '调查快',
          lanhutext1: '自建调查团队，覆盖各区'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3d10a5760d9748939677f0c037dc419d_mergeImage.png) 100% no-repeat',
          lanhutext0: '取证快',
          lanhutext1: '调查完30天内取证'
        },
        {
          lanhuBg0:
            'url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/826c1f29a5c949a7b2c33935dce6b7e5_mergeImage.png) 100% no-repeat',
          lanhutext0: '出书快',
          lanhutext1: '取证完30日内出书'
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />