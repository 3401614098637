import Vue from 'vue'
import VueRouter from 'vue-router'
import investigation from '../views/investigation/index.vue'
import home from '../views/home/index.vue'
import pricemonitoring from '../views/pricemonitoring/index.vue'
import AboutUs from '../views/aboutus/index.vue'
import legalaction from '../views/legalaction/index.vue'
import team from '../views/team/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/investigation',
    name: 'investigation',
    component: investigation
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/pricemonitoring',
    name: 'pricemonitoring',
    component: pricemonitoring
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUs
  },
  {
    path: '/legalaction',
    name: 'legalaction',
    component: legalaction
  },
  {
    path: '/team',
    name: 'team',
    component: team
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
