<template>
  <div class="block_14 flex-col">
    <div class="text-wrapper_9 flex-row">
      <span class="text_57">产品中心</span>
      <span class="text_58">最新动态</span>
      <span class="text_59">关于我们</span>
      <span class="text_60">加入我们</span>
      <span class="text_61">咨询合作</span>
    </div>
    <div class="group_34 flex-row">
      <div class="box_16 flex-col"></div>
      <div class="box_17 flex-col"></div>
      <div class="box_18 flex-col"></div>
      <div class="box_19 flex-col"></div>
      <div class="box_20 flex-col"></div>
    </div>
    <div class="group_35 flex-row">
      <div class="text-wrapper_10 flex-col justify-between">
        <span class="text_62">侵权诉讼</span>
        <span class="text_63">网络控价</span>
      </div>
      <div class="text-wrapper_11 flex-col justify-between">
        <span class="text_64">公司动态</span>
        <span class="text_65">公告公示</span>
      </div>
      <div class="text-wrapper_12 flex-col justify-between">
        <span class="text_66">公司理念</span>
        <span class="text_67">团队介绍</span>
      </div>
      <div class="text-group_29 flex-col justify-between">
        <span class="text_68">社会招聘:</span>
        <span class="text_69">18668204021</span>
      </div>
      <div class="text-wrapper_13 flex-col justify-between">
        <span class="text_70">18668204021</span>
        <span class="text_71">使用帮助&nbsp;&nbsp;（&nbsp;工作日：9:30&nbsp;-&nbsp;18:30&nbsp;）</span>
      </div>
    </div>
    <div class="group_36 flex-row justify-between">
      <span class="text_72">荣誉奖励</span>
      <div class="text-group_30 flex-col justify-between">
        <span class="text_73">校园招聘:</span>
        <span class="text_74">18668204021</span>
      </div>
    </div>
    <div class="image-wrapper_7 flex-row justify-between">
      <img
        class="image_24"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng61754ff780e5602e44e456b395cada2d8db6be7a8767d27e4294f316499ad714.png"
      />
      <img
        class="image_25"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPnga3aa6bb587e4ca2f713db8bb6d358dc4e16ea0fc60c796f36dd7c18efe72b712.png"
      />
    </div>
    <div class="group_37 flex-col"></div>
    <div class="group_38 flex-row">
      <span class="text_75">浙ICP备2023010140号</span>
      <img
        class="thumbnail_15"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng23a4142103e0d4bbd6efb995ac0ea8e1f55610dbbf0f359fd632aaa8480d2142.png"
      />
      <span class="text_76">赣公网安备&nbsp;XXXXXXXXXXX号</span>
    </div>
  </div>
</template>

<script>

export default {
  name:'CommonFooter',
  data(){return {}}
}
</script>

<style scoped lang="css">
.block_14 {
  background-color: rgba(36, 43, 64, 1);
  width: 1920px;
  height: 430px;
}
.text-wrapper_9 {
  width: 977px;
  height: 18px;
  margin: 60px 0 0 360px;
}
.text_57 {
  width: 72px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 27px;
}

.text_58 {
  width: 72px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 27px;
  margin-left: 150px;
}
.text_59 {
  width: 72px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 27px;
  margin-left: 150px;
}

.text_60 {
  width: 72px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 27px;
  margin-left: 150px;
}

.text_61 {
  width: 72px;
  height: 18px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 27px;
  margin-left: 167px;
}
.box_16 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  width: 20px;
  height: 2px;
}

.box_17 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  width: 20px;
  height: 2px;
  margin-left: 202px;
}

.box_18 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  width: 20px;
  height: 2px;
  margin-left: 202px;
}

.box_19 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  width: 20px;
  height: 2px;
  margin-left: 202px;
}

.box_20 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
  width: 20px;
  height: 2px;
  margin-left: 219px;
}
.group_34 {
  width: 925px;
  height: 2px;
  margin: 20px 0 0 360px;
}
.group_35 {
  width: 1131px;
  height: 48px;
  margin: 32px 0 0 360px;
}
.text-wrapper_10 {
  width: 56px;
  height: 48px;
}
.text-wrapper_11 {
  width: 56px;
  height: 48px;
  margin-left: 166px;
}
.text-wrapper_12 {
  width: 56px;
  height: 48px;
  margin-left: 166px;
}
.text_62 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
}

.text_63 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 20px;
}



.text_64 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
}

.text_65 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 20px;
}



.text_66 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
}

.text_67 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 20px;
}

.text-group_29 {
  width: 89px;
  height: 38px;
  margin-left: 166px;
}

.text_68 {
  width: 60px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
}

.text_69 {
  width: 89px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 10px;
}

.text-wrapper_13 {
  width: 226px;
  height: 48px;
  margin-left: 150px;
}

.text_70 {
  width: 89px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
}

.text_71 {
  width: 226px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 20px;
}

.group_36 {
  width: 308px;
  height: 38px;
  margin: 14px 0 0 804px;
}

.text_72 {
  width: 56px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 6px;
}

.text-group_30 {
  width: 86px;
  height: 38px;
}

.text_73 {
  width: 60px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
}

.text_74 {
  width: 86px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 10px;
}

.image-wrapper_7 {
  width: 1201px;
  height: 53px;
  margin: 47px 0 0 360px;
}

.image_24 {
  width: 260px;
  height: 53px;
}

.image_25 {
  width: 302px;
  height: 50px;
  margin-top: 2px;
}

.group_37 {
  background-color: rgba(255, 255, 255, 0.1);
  width: 1200px;
  height: 1px;
  margin: 40px 0 0 360px;
}

.group_38 {
  width: 302px;
  height: 17px;
  margin: 20px 0 20px 809px;
}

.text_75 {
  width: 123px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(173, 173, 173, 1);
  font-size: 12px;
    text-align: right;
  white-space: nowrap;
  line-height: 17px;
}

.thumbnail_15 {
  width: 14px;
  height: 14px;
  margin: 1px 0 0 10px;
}

.text_76 {
  width: 151px;
  height: 12px;
  overflow-wrap: break-word;
  color: rgba(173, 173, 173, 1);
  font-size: 12px;
  font-family: SourceHanSansCN-Regular;
    text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin: 2px 0 0 4px;
}
</style>