<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <CommonMenuBar theme="white" />

      <div class="image-wrapper_1 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="http://qiniu.anyuanip.com/home/aboutus/SketchPngf8c711814b2e5829bb67f4de5be42a8555c80aa5ec638d93d014aefa89a84034.png"
        />
      </div>
      <div class="text-wrapper_1 flex-row"><span class="text_8">About&nbsp;us</span></div>
      <div class="text-wrapper_2 flex-row">
        <span class="paragraph_1">
          案元科技永远定位于服务权利人，服务律师，服务所有维护知识产权权利的人。
          <br />
          我们将持续投诉技术研发，让维权更简单
        </span>
      </div>
      <div class="flex-row justify-between" style="margin:85px auto;width:800px;">
        <div class="tag-box flex-col align-center" v-for="(item, index) in loopData0" :key="index">
          <img class="image_3" referrerpolicy="no-referrer" :src="item.lanhuimage0" />
          <span class="text-group_1" v-html="item.lanhutext0"></span>
        </div>
      </div>
    </div>
    <div class="group_4 flex-col">
      <div class="block_1 flex-col">
        <span class="text_9">我们的价值观</span>
        <div class="list_2 flex-row">
          <div
            class="list-items_1 flex-col"
            :style="{ background: item.lanhuBg0 }"
            v-for="(item, index) in loopData1"
            :key="index"
          >
            <span class="text_10" v-html="item.lanhutext0"></span>
            <div class="section_1 flex-col"></div>
            <span class="text_11" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <div class="block_2 flex-col">
        <div class="text-wrapper_3 flex-row"><span class="text_12">我们的做事指引</span></div>
        <div class="text-wrapper_4 flex-row"><span class="text_13">解决深度</span></div>
        <div class="group_5 flex-col">
          <span class="text_14">问题深度</span>
          <div class="section_2 flex-row">
            <div class="block_3 flex-row">
              <div class="image-text_2 flex-col justify-between">
                <img
                  class="image_4"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/aboutus/SketchPng2a1dc1a725a3ed1d5fa19fcb7c138869a82314f32f049fc521d57e96bbd5725e.png"
                />
                <span class="text-group_2">仔细研究问题</span>
              </div>
            </div>
            <div class="block_4 flex-row">
              <div class="image-text_3 flex-col justify-between">
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/aboutus/SketchPng7879ca0f1215a4ba0000d254c32ae6cf42b94cf8f992ff6b2e83737e8683a6ee.png"
                />
                <span class="text-group_3">充分解决问题</span>
              </div>
            </div>
            <div class="block_5 flex-row">
              <div class="image-text_4 flex-col justify-between">
                <img
                  class="image_6"
                  referrerpolicy="no-referrer"
                  src="http://qiniu.anyuanip.com/home/aboutus/SketchPnge1233421228e783dd49cd01ea39044c4bc7a21dbb51ad5f75ca567ac90657b91.png"
                />
                <span class="text-group_4">有价值的工作</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group_7 flex-col">
      <span class="text_35">4部门服务客户</span>
      <div class="group_8 flex-row justify-between">
        <div class="box_4 flex-col"></div>
        <div class="box_5 flex-col"></div>
      </div>
      <div class="group_9 flex-row justify-between">
        <div class="text-group_7 flex-col justify-between">
          <span class="text_36">项目服务部</span>
          <span class="text_37">负责与权利人、以及项目其他相关人员的对接，推进所有案件快速，高效办理完成。</span>
        </div>
        <div class="text-group_8 flex-col justify-between">
          <span class="text_38">线索支持部</span>
          <span class="text_39">为签约项目全面快速地挖掘线索提供支持，线上采集软件支持，线下专业经验服务</span>
        </div>
      </div>
      <div class="group_10 flex-row justify-between">
        <div class="group_11 flex-col"></div>
        <div class="group_12 flex-col"></div>
      </div>
      <div class="group_13 flex-row justify-between">
        <div class="text-wrapper_10 flex-col justify-between">
          <span class="text_40">技术部</span>
          <span class="text_41">开发并维护电商采集系统、案件管理系统、线索录入系统，为办案提供强有力的软件支持</span>
        </div>
        <div class="text-group_9 flex-col justify-between">
          <span class="text_42">财务核算部</span>
          <span class="text_43">为权利人和律师准确，快速地完成账务核对而保驾护航，确保账务清晰，结算快速</span>
        </div>
      </div>
    </div>

    <CommonFooter />
  </div>
</template>
<script>
import CommonMenuBar from '../common/menu_bar/index.vue';
import CommonFooter from '../common/footer';
export default {
  components:{CommonMenuBar, CommonFooter},
  data() {
    return {
      loopData0: [
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng481de0847ce1cd542f0adb11d040e9d665e0dffc47f39d478589ce6d38fa28a1',
          lanhutext0: '技术驱动'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng09f8e4d5ff4d9709862f56eb3cd302e02e4c504240eeaf21c1af4f326f2616a0',
          lanhutext0: '坚持真确价值观'
        },
        {
          lanhuimage0:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngca3abf49a2b81604999b2fafa798fd9c79976ce5cd37f4a66a762f4f8a79b697',
          lanhutext0: '长期主义'
        }
      ],
      loopData1: [
        {
          lanhuBg0:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0e6327c984cb851ab4020589bdf5ca391cd5957d538f0ef7141ebdf77e0be7b2) -1px -1px no-repeat',
          lanhutext0: '对权利人',
          lanhutext1: '让权利人能在案元网切实地享受到权利保护的作用'
        },
        {
          lanhuBg0:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng152ccc65b2c3dd011633e62af5c3ac46e43c108127ba9fc7620831167af38f85) -1px -1px no-repeat',
          lanhutext0: '对律师',
          lanhutext1: '让律师在案元网有案件做，而且办案更简单'
        },
        {
          lanhuBg0:
            'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngfd9ea328ac42f522e6af9ad8d844cd11ccb367fc38ce30a73bdcc866bd5e55e7) -1px -1px no-repeat',
          lanhutext0: '对社会',
          lanhutext1: '做对社会进步有价值的事，不急功近利'
        }
      ],
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />